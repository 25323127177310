<template>
   <div>
       <van-nav-bar title="选择城市" left-arrow @click-left="$router.go(-1)" />
   </div>
</template>

<script>
export default {

}
</script>

<style scoped>

</style>